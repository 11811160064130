@import "_vars.scss";

:export {
    darkMode: $dark-mode-masthead;
    lightMode: $light-mode-masthead;
}

.DarkModeMastheadBtn {
    background-color: $dark-mode-masthead;
    color: white;
    border: 0.5px solid white;
}

.LightModeMastheadBtn {
    background-color: $light-mode-masthead;
}