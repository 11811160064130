@import '~@amzn/meridian-tokens/base/_elevation.scss';
@import '~@amzn/meridian-tokens/base/_color.scss';

$global_paragraph_padding: 10px 0px 10px 0px;

$view-padding: 3%;
$view-background-color: white;
$header-background-color: #1496d6;
$center-content-maxwidth: 1265px;

$control-bar-background: #f0f0f1;
$widget-headers: #687476;

$alert-red: $color-red-400;
$warning-yellow: $color-yellow-400;
$link-blue: $color-blue-500;
$hover-blue: $color-blue-600;
$link-blue-dark-mode: $color-blue-400;
$hover-blue-dark-mode: $color-blue-500;

$employee-yellow: #dcb700;
$employee-blue: #0096d6;
$employee-green: #98c91e;
$employee-unknown: $color-pink-400;
$employee-white: $color-gray-300;
$secondary-gray: $color-gray-500;

$chart-green: $color-turquoise-400;
$chart-purple: $color-purple-400;
$chart-violet: $color-violet-400;
$chart-gold: $color-gold-400;
$chart-pink: $color-pink-400;
$chart-tangerine: $color-tangerine-400;

$meridian_heading_h500: h500;
$meridian_heading_h100: h100;
$meridian_heading_d50: d50;

$animation_ph_pulse: ph-pulse-anim;

// WHS Colors.
$color-smile: #ff9900;
$color-prime-blue: #1a98ff;
$color-gateway-prime-blue: #007eb4;
$color-midnight: #002552;
$color-prime-blue-light: #e1f2fa;
$color-iris: #9CD5F3;

// Dark Theme colors
$light-mode-background: $color-blue-100;
$dark-mode-masthead: $color-blue-600;
$dark-mode-background: $color-gray-700;
$light-mode-masthead: #1496d6;