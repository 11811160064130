@import "_vars.scss";
@import "~@amzn/meridian-tokens/base/font/amazon-ember.css";
@import "~animate.css/animate.css";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Amazon Ember";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/** Ensures Meridian card backgrounds are solid white with some space */
// div[role="region"] {
//   background-color: white;
// }

.globalPadding10px {
  padding: 10px 0;
}

.globalPaddingRight10px {
  padding-right: 10px;
}

.globalPaddingTop10px {
  padding-top: 10px;
}

.globalPaddingTop20px {
  padding-top: 20px;
}

.globalPaddingLeft10px {
  padding-left: 10px;
}

.globalParagraph {
  padding: $global_paragraph_padding;
}

.globalBoldItem {
  font-weight: 700;
}

.globalBoldTitle {
  font-weight: 700;
  font-size: 24px;
}

.globalViewHeader {
  font-size: 36px;
  font-weight: 200;
}

.globalFlexRow {
  display: flex;
  flex-direction: row;
}

.globalFlexColumn {
  display: flex;
  flex-direction: column;
}

.globalPositionRelative {
  position: relative;
}

.globalFullWidth {
  width: 100%;
}

.globalFullHeight {
  height: 100%;
}

/** For use in placeholder loading skeletons */
.ph-pulse-anim {
  animation: ph-pulse 1.5s infinite;
}
@keyframes ph-pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
