@import "_vars.scss";

.headerPosition {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: $elevation-masthead;
}

.viewPosition {
  padding: 0;
  margin: auto;
  max-width: 1750px;
}

:export {
  darkModeBackground: $dark-mode-background;
}
